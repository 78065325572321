/* my framework */
/*  style={{ color: `deeppink` }} */
/* <span className="why-us">1</span> */
/* <code dir="ltr" className="code"> display: grid; </code> */
/* &nbsp; == space  */
/* &lt;   ==    <   */
/* &gt;   == > */
/* &#123; == {} */
/* <span role="img" aria-label="hahaha">&#128514;</span> */
/* <span role="img" aria-label="love emoji">&#128525;</span> */
/* <span role="img" aria-label="heart emoji">&#129505;</span> */
/* <span role="img" aria-label="emoji"> &#128071;  70 أعلى</span> */
/* <span role="img" aria-label="warning" class="warning">&#9888;</span>  */





.code {
  color: crimson;
  background-color: #f1f1f1;
  padding: 1px 4px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  letter-spacing: -1px;
  display: inline-block;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: Consolas, "courier new";
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mb {
  margin-bottom: 25px !important;
}

.blue {
  color: royalblue;
  font-weight: bold;
  letter-spacing: 0.03rem;
}

.blue-dark {
  color: royalblue;
  font-weight: bold;
}

.dark .blue-dark {
  color: antiquewhite;
}

.bluegold {
  color: royalblue;
  margin: 1rem;
}

.dark .bluegold {
  color: antiquewhite;
}

.dark .gold {
  color: antiquewhite;
}

.inline-block {
  display: inline-block;
  margin: 0 0.5rem;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.fl {
  float: left;
  position: relative;
  bottom: 9px;
  color: #fff;
  font-weight: bold;
}

.fr {
  float: right;
  position: relative;
  bottom: 9px;
  color: #fff;
  font-weight: bold;
}

.source {
  cursor: pointer;
}

.table-2col {
  width: 60%;

  font-weight: 500;
  margin-bottom: 3rem;
}

.divvid {
  text-align: center;
  margin: 4rem 0;
}

.divvid .mybtn {
  font-size: 1.6rem;
}

.videoarticle {
  width: 90%;
  height: 30rem;
  border-radius: 1rem;
  border: 2px solid #777;
  margin: 2rem 0;
}

@media (max-width: 600px) {


  .videoarticle {
    width: 98%;
    height: 20rem;
   
     
  }
}

.source:hover {
  font-weight: bolder;
  border-bottom: 2px solid rgb(252, 67, 100);
}

.left-section-course {
  width: 50%;
  padding-bottom: 1rem;
}

@media (max-width: 576px) {
  .left-section-course {
    float: none;
    width: 100%;
  }
}

.no-style {
  list-style: none;
  font-weight: normal;
}

.source-right {
  float: right;
  font-weight: 400;
  margin-right: 2rem;
}

.source-left {
  display: inline-block;
  font-weight: 400;
  margin-left: 2rem;
}

.dark .source-left,
.dark .source-right {
  color: #faebd7;
}

.in-this-course {
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: royalblue;
}

.dark .in-this-course {
  color: antiquewhite;
}

@media (max-width: 1148px) {
  .source-right {
    float: none;
    margin-top: 3rem;
  }

  .source-left {
    margin-left: 0;
  }
}

.w3org {
  margin-left: 3rem;
  color: #333;
  font-size: x-large;
}

.dark .w3org {
  color: white;
}

.dark .w3schools {
  color: white;
}

.w3schools {
  margin-left: 3rem;
  color: #333;
  font-size: x-large;
}

.hidden {
  display: none;
}

.dark {
  background-color: #2d2d2d;
  color: white;
}

@media (max-width: 990px) {
  .showBig {
    display: none;
  }
}

/*  prism file */
code[class*="language-"],
pre[class*="language-"] {
  color: #f8f8f2;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 0.9em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.3;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

 

pre[class*="language-"] {
  padding-left: 1em;
  padding-bottom: 1em;
  margin: 1em 0.5rem;
  overflow: auto;
  border-radius: 0.5em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #2d2d2d;
  border: 1px solid lightpink;
  border-bottom: 2px solid lightpink;
  overflow-x: auto;
  font-weight: normal;
}

 

:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.5em;
  white-space: normal;
}

.token.punctuation {
  color: #f8f8f2;
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #fc4364;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
}

@media (max-width: 500px) {
  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    font-size: 15px;
  }
}

.token.boolean,
.token.number {
  color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #e6db74;
}

.token.keyword {
  color: #66d9ef;
}

.token.regex,
.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}


mark {
  background-color: #fc4364;
  color: #fff;
  margin: 0.5rem;
  padding: 3px 6px;
  border-radius: 0.5rem;
  display: inline-block;
}

.dark mark {
  box-shadow: 0 7px 93px 0 rgba(255, 131, 131, 0.47);
}

.dark .html-sidebar {
  scrollbar-color: #9e4673 rgba(255, 131, 131, 0.08);
}

li:focus {
  border-bottom: 2px solid #fc4364;
}

.warning {
  font-size: 2.5rem;
  color: #fc4364;
  position: relative;
  top: 0.3rem;
  padding: 0.6rem;
}

/* 1- Header */

/* .ul-hidden:hover > ul{
display: block;} */

/* end header */

/* 2- our goal */

.right-section {
  text-align: center;
  width: 50%;
}

.left-section {
  text-align: center;
  width: 50%;
}

.pic {
  background-image: url(https://res.cloudinary.com/dpm5z8e71/image/upload/v1668265131/me_qdwodc.jpg);
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  height: 10rem;
  width: 10rem;

  margin-top: 1rem;
  animation: radiuscol 3s infinite;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin-left: auto;
  margin-right: auto;
}

.dark .pic {
  box-shadow: 5px -7px 20px 0 rgba(255, 131, 131, 0.47);
}

@keyframes radiuscol {
  0%,
  100% {
    border-right: 2px solid #ff8a00;
    border-left: 2px solid #ff8a00;
    border-bottom: 2px solid #e52e71;
    border-top: 2px solid #e52e71;
  }

  50% {
    border-bottom: 2px solid #ff8a00;
    border-top: 2px solid #ff8a00;
    border-left: 2px solid #e52e71;
    border-right: 2px solid #e52e71;
  }
}

.left-section .btn {
  margin: 1rem;
  border-radius: 0.5rem;
}

.goal-title {
  font-size: 1.7rem;
  /* color: deeppink; */
  color: #646464;
  margin: 3rem 0 1rem 0;
}

.dark .goal-title {
  color: antiquewhite;
}

@media (max-width: 576px) {
  .right-section,
  .left-section {
    float: none;
    width: 100%;
  }

  .pic {
    margin-top: 3rem;
  }
}

/* end our goal */

/* 3- why us */

.why-us {
  background-color: #fc4364;
  padding: 0.1rem 0.53rem;
  color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 1rem;
  box-shadow: 0 7px 93px 0 rgba(255, 131, 131, 0.47);
}



/* general */

li {
  margin-top: 0.25em;
}

html a {
  text-decoration: none;
  color: inherit;
}

.card {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.25);
  width: 98%;
  margin: 0 auto;
  border-radius: 10px;
  height: 100%;
  padding: 1%;
  overflow: auto;
}

.dark .card {
  box-shadow: 0 7px 93px 0 rgb(255 131 131 / 47%);
  background-color: rgba(255, 131, 131, 0.08);
}

.ml {
  margin-left: 1rem;
}

.mt {
  margin: 0.5em;
}

.mtt {
  margin-top: 1rem;
}

.mttt {
  margin-top: 1.5rem;
}

.mtttt {
  margin-top: 2rem !important;
}

.center {
  text-align: center;
}

.container {
  width: 100%;
  margin: auto;
}
@media (max-width: 576px) {
  .container {
    padding-inline: 0.6rem;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 688px) {
  .family {
    flex-direction: column;
  }

  .box {
    margin-bottom: 2.5rem;
    margin-top: 0.5rem;
    padding: 0.7rem;
  }
}

@media (max-width: 490px) {
  .box {
    margin-bottom: 3.5rem;
    padding: 0.7rem;
    margin-top: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .box {
    width: 50%;
    width: fit-content;
  }
}

.mybtn {
  margin: 0.5rem;
  color: #3d4a59;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #33557c;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: all 0.5s;
}

.dark .mybtn {
  box-shadow: 0px 0px 0px #9e4673, 0 0 10px #9e4673, 0 0 0px #9e4673;
  border: 1px solid #fff;
  color: #fff;
}

.mybtn:hover {
  background-color: #fc4364;
  color: #fff;
  font-size: 1.03rem;
}

.dark .mybtn:hover {
  background-color: #fc4364;
  font-size: 1.03rem;
}

/* HTML PAGE  */

.overflow {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.9rem;
  display: flex;
  flex-wrap: wrap;
}

/* Advertise section */

.nav-advertise {
  width: 20%;
}

@media (max-width: 1199px) {
  .nav-advertise {
    width: 30%;
    margin: 1px auto;
  }
}

.html-sidebar {
  background-color: #28568b;
  overflow-y: auto;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1.07rem;
  border: 1px solid antiquewhite;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
}

.html-left-section {
  width: 80%;
  padding: 1rem;
}

.advertise {
  margin-top: 60px;
  background-color: #28568b;
  border-radius: 0.5rem;
  color: #ffffff;
  border: 1px solid antiquewhite;
  padding: 1rem;
}

@media (max-width: 1199px) {
  .html-left-section {
    width: 70%;
  }
}

@media (max-width: 990px) {
  .nav-advertise {
    width: 95%;
  }

  .html-sidebar {
    font-size: 1.2rem;

    height: 320px;
  }

  .html-left-section {
    width: 100%;
  }

  .advertise {
    display: none;
  }

  .hidden {
    display: inline;
  }

  .css-sidebar {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
}

.dark .html-sidebar,
.dark .advertise {
  box-shadow: 0px 2px 10px 0 rgb(255 131 131 / 47%);
  background-color: #121212;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
}

/* End of advertise section */

dl {
  padding: 1.5rem 0;
  line-height: 1.8rem;
  font-size: 1.35rem;
}

dt {
  font-weight: bold;
  padding-bottom: 0.5rem;
  margin-top: 4rem;
}

dd {
  font-size: 1.15rem;
}

.title {
  margin-top: 4rem !important;
}

.html-left-section ul {
  padding: 1rem 0;
}

.div-border {
  border: 2px solid;
  margin: 2%;
  text-align: center;
  font-size: 1.6rem;
}

.article-img {
  margin: auto;
  text-align: center;
  border-radius: 0.5rem;
  max-width: 100%;
  height: auto;
}

.dark img {
  border: 1px solid lightpink;
}

.emojimove {
  animation: emoji 3s infinite;
}

@keyframes emoji {
  0%,
  100% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }
}

summary {
  outline: none;
  cursor: pointer;
}

.youtube {
  border: 1px solid #fff;
  background-color: #fc4364;
  color: #fc4364;
  color: #fff;
}

.dark .youtube {
  background-color: transparent;
}

.facebook {
  border: 1px solid #fff;
  background-color: royalblue;
  color: #fff;
}

.dark .facebook {
  background-color: transparent;
}

.facebook:hover {
  border: 1px solid #fff;
  background-color: royalblue;
  color: #fff;
}

.instagram {
  color: #fff;
  border: 1px solid #fff;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f5c33b 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* 
 codes from file 
*/

.iframe-container {
  border-radius: 1rem;
  float: left;
  /* min-width: 75%; */
  margin: 0 auto;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 3rem;
  width: calc(100% - 19rem);
}

.videoWrapper iframe {
 
  width: 100%;
  border-radius: 0.9rem;
  border: 2px solid #777;
  aspect-ratio: 16/9;
}

.sidebar {
  height: 45rem;
  background-color: #3d4a59;
  overflow-y: scroll;
  border-radius: 0.5rem;
  color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid antiquewhite;
  float: right;
  width: 17rem;
}

.sidebar .link-container {
  /* margin-right: 1.3rem; */
  text-decoration: none;
  list-style: none;
  margin-top: 1rem;
  line-height: 1.5rem;
}

.dark .sidebar {
  box-shadow: 0 7px 93px 0 rgba(255, 131, 131, 0.47);
  background-color: rgba(255, 131, 131, 0.08);
}

.big1 {
  font-size: 1.2rem;
}

.big2 {
  font-size: 1.22rem;
  line-height: 1.7em;
}

.big3 {
  font-size: 1.6rem;
  line-height: 1.7em;
}

.small {
  font-size: 1.1rem !important;
}

.left {
  text-align: left;
}

.padding {
  padding: 1rem;
}

.link2 {
  color: royalblue;
  transition: 0.3s;
}

.link2:hover {
  font-weight: bold;
  border-bottom: 2px solid #fc4364;
}

.dark .link2 {
  color: antiquewhite;
}

dfn {
  letter-spacing: 0.5px;
}

span[role="img"] {
  font-weight: 500;
}

@media (min-width: 1200px) {
  .videoWrapper iframe {
    width: 67%;
  }

  .videoWrapper {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .table-2col {
    width: 100%;
  }
  .videoWrapper iframe {
    width: 99%;
    display: block;

  }

  .sidebar {
    height: 30rem;
  }
}

@media (max-width: 600px) {
  .sidebar {
    display: block;
    width: 90%;
    height: auto;
    float: none;
    margin: 0 auto;
  }

  .iframe-container {
    width: 100%;
    margin: 2rem auto;
    display: block;
  }

  .sidebar .link-container {
    width: fit-content;
    margin: auto;
  }

  .big2,
  .big1 {
    font-size: 1rem;
    line-height: 1.7em;
    margin-left: 11px;
  }
}

/* الجديددددددددددددددددددددددددددددددددددددددددددددددددددددددددددد */
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: sans-serif, Arial, Helvetica, sans-serif;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  scroll-behavior: smooth;
  transition: all 0.5s;
  box-sizing: border-box;
  color: #3d4a59;
  scrollbar-color: #9e4673 #3d4a59;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p {
  margin-bottom: 1rem;
}

::-webkit-scrollbar {
  width: 0.85rem;
}

::-webkit-scrollbar-track {
  border: 7px solid #232943;
  box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  /* background: linear-gradient(90deg, #9e4673, #3d4a59); */
  border-radius: 2px;
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  /* background: linear-gradient(270deg, #9e4673, #3d4a59, #9e4673); */
  background: #555;
}

del,
s {
  text-decoration: line-through;
}

ins,
u {
  text-decoration: none;
  border-bottom: 2px solid red;
  display: inline-block;
  padding-bottom: 10px;
}

u {
  border-bottom: 4px solid rgb(255, 65, 93);
  border-radius: 10px;
  padding: 0 30px 10px 30px;
  margin-bottom: 1.5rem;
}

table,
th,
td {
  border: 1px solid #3d4a59;
  text-align: center;
  margin: auto;
}

.dark table,
.dark th,
.dark td {
  border: 1px solid #fff;
}

table {
  border-collapse: collapse;
}

td {
  padding: 1rem;
}

tr .mybtn {
  color: royalblue;
}

th {
  padding: 1rem;
  font-weight: 500;
}

caption {
  margin-bottom: 2rem;
}

ol,
ul {
  line-height: 2rem;
  list-style-position: inside;
  padding: 1rem 1rem;
}

@media (max-width: 500px) {
  ol,
  ul {
    padding: 1rem 0;
  }
  .in-this-course {
    font-size: 1.3rem;
  }
  .divvid .mybtn {
    font-size: 1rem;
  }
  .hidden {
    display: none;
  }
  .html-sidebar {
    padding-right: 0;
  }
  .css-sidebar {
    padding-right: 3rem;
  }
  .code {
    font-size: 1.1rem;
  }
}

strong {
  margin: 0.5rem;
  display: inline-block;
}

footer {
  margin-top: 5rem;
}

.footer {
  background-color: #154e87;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.2rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dark .footer {
  background-color: #121212;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
}
.start-with-us {
  text-align: center;
  opacity: 0.85;
  margin-bottom: 0.8rem !important;
  font-size: 1.1rem;
  font-weight: 500;
  border-top: 4px solid #1976d2;
}
.dark .start-with-us {
  border-top: 4px solid #ffffff;
}
.start-with-us:hover {
  box-shadow: inset rgb(255 131 131 / 47%) 0px 7px 17px 0px !important;
}
@media (max-width: 446px) {
  .start-with-us {
    font-size: 0.85rem;
  }
  .footer {
    font-size: 0.85rem;
  }
}
 
 
.link {
  text-decoration: none;
  color: #fff;
}
.fam-link {
  color: #3d4a59;
}
.fam-link:hover {
  font-size: 1.15rem;
}
.adsbygoogle {
  border: none;
}
.header-li {
  text-decoration: none;
  color: seashell;
  cursor: pointer;
}
.header-li label {
  cursor: pointer;
}
.dark .header-li {
  color: antiquewhite;
}
.header-li:hover > div {
  display: flex;
  justify-content: space-around;
  position: absolute;
  left: 10vw;
  right: 10vw;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.6);
  padding: 1rem;
  border-radius: 0.5rem;
  color: #3d4a59;
  margin-right: 1rem;
  background-color: #fff;
}
.ul-hidden {
  display: none;
  z-index: 5;
}

.list {
  color: orange !important;
  font-weight: bold;
}
.dark .link .list {
  color: antiquewhite;
}
.dark .link {
  color: antiquewhite;
}
.active {
  font-weight: 700;
  border-bottom: 2px solid #fc4364;
}
.link:hover,
.link:focus {
  font-weight: bold;
  border-bottom: 2px solid #fc4364;
}
.ul-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  font-size: 1.2rem;
}
.nav .navx ul li {
  padding: 0 1rem;
}
.learn-li {
  list-style: none;
  padding: 0;
}
.logo:hover {
  font-size: x-large;
}
.theme-btn {
  outline: none;
  /* border-radius: 1rem; */
  /* padding: 0.1rem 0.5rem; */

  /* border: 1.5px solid #ddd; */
  cursor: pointer;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  color: wheat;

  /* background-color: rgb(56 56 56); */
}
/* .dark .theme-btn {
box-shadow: 0 0 10px #9e4673, 0 0 40px #9e4673, 0 0 80px #9e4673;
background: #9e4673;
}
.theme-btn:hover {
box-shadow: 0 0 10px #9e4673, 0 0 40px #9e4673, 0 0 80px #9e4673;
background: #9e4673;
border: none;
outline: none;
}
.theme-btn:focus {
padding: 4px 12px;
box-shadow: 0 0 10px #9e4673, 0 0 40px #9e4673, 0 0 80px #9e4673;
box-shadow: 0 0 0 5px #3d4a59, 0 0 0 6px white;
}
.dark .theme-btn:focus {
box-shadow: 0 0 0 2px #faebd7;
} */

.nav {
  background-color: #175b9f;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dark .nav {
  /* background-color: rgba(255, 131, 131, 0.08); */
  background-color: #121212;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
  border-bottom: 1px solid antiquewhite;
}
.logo {
  color: #ffffff;
  transition: all 0.3s;
  font-family: sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
  margin-left: 44px;
}

.navx {
  margin-right: 22px;
}

@media (max-width: 700px) {

  .navx {
    margin-right: 0;
  }

  .nav {
    flex-direction: column;
  }
  .logo {
    font-size: 1.2rem;
    margin-left: 0;
  }
  .ul-header {
    font-size: 1rem;
    padding: 0;
  }
  .nav .navx ul li {
    padding: 1rem;
  }
}
@media (max-width: 350px) {
  .ul-header {
    flex-direction: column;
  }
}
ol li {
  list-style: none;
}

ol {
  counter-reset: plusone;
}

ol li::before {
  counter-increment: plusone;
  content: counter(plusone);
  background-color: #fc4364;
  padding: 0.1rem 0.45rem;
  color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  font-size: 1.2rem;
  box-shadow: 0 7px 93px 0 rgba(255, 131, 131, 0.47);
}
.border {
  border: 2px solid lightpink;
  border-radius: 1rem;
  padding: 6px;
  border-left: 10px solid #fd6c6c;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}
.box {
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #1976d2;
  padding: 0.7rem 3rem;
  font-size: 1.5rem;
  margin: 1rem auto 3rem;
  border-radius: 5px;
}

.dark .box {
  background-color: #9e4673;
  /* box-shadow: 0 7px 20px -5px rgba(255, 131, 131, 0.47); */
}

.support {
  border: 2px solid #fa923f;
  border-radius: 12px;
  padding: 0 1rem !important;
  transition: all 0.3s;
  animation: changecolor 10s infinite;
}

.support:hover {
  background-image: linear-gradient(
    to left,
    rgb(242, 25, 170),
    rgb(112, 38, 185)
  );
  border: 3px solid #fff;
  animation: none;
}

@keyframes changecolor {
  0%,
  100% {
    border: 2px solid #fd6c6c;
  }
  25% {
    border: 2px solid #f219aa;
  }
  50% {
    border: 2px solid red;
  }
  65% {
    border: 2px solid #7026b9;
  }
  75% {
    border: 2px solid #f219aa;
  }
}
