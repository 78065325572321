.wrapper{

  position: absolute;
  z-index: 5;
  /* max-width: 480px; */
  top: 50%;
  left: 50%;
  
  padding: 40px 28px;
  background: #fff;
  border-radius: 15px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 10px 10px 15px rgba(0,0,0,0.06);
  transition: opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
  width: 80vw;
  height: 75vh;
  display: flex;
  justify-content: center;
}    
.wrapper.show{
  opacity: 1;
  pointer-events: auto;
  /* transform:translate(-50%, -50%) scale(1); */
  background-color: bisque;
}
.dark .wrapper.show{
  background-color: white;
}
.wrapper .content,
.content .warn-icon,
.warn-icon .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .content{
  flex-direction: column;
}
.content .warn-icon{
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background: linear-gradient(lightpink  0%, lightpink  0%, #d33639 100%, #f92121 100%);
}
.warn-icon .icon{
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: inherit;
}
.warn-icon .icon i{
  /* background: linear-gradient(#9b27ca 0%, #9927cf 0%, #d33639 100%, #f92121 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 50px;
}
.content h2{
  margin-top: 35px;
  font-size: 32px;
}
.content p{
  font-size: 19px;
  text-align: center;
  margin-top: 20px;
}
.adbtn{
  height: 57px;
  width: 135px;
  margin-top: 30px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.adbtn .bg-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(135deg, lightpink , #d33639, lightpink , #d33639);
  transition: all 0.4s ease;
}
.adbtn:hover .bg-layer{
  left: 0;
}
.content button{
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 18px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}